import React from "react"
import { Router } from "@reach/router"
import IndexPage from "./index"
import PrivacyPolicy from "./privacy-policy"
import TermsAndConditions12Months from "./tnc/12mth"
import ErrorPage from "./maintenance"
import PageNoFound from "./page-not-found"
import DeviceDetailsPage from "./device-info"
import ContactInformationPage from "./contact-info"
import TosPage from './tos'
import DisclaimerPage from './disclaimer'

const App = () => {
  return (
    <Router basepath="/">
      <IndexPage path="/" />
      <PrivacyPolicy path="/privacy-policy" />
      <TermsAndConditions12Months path="/tnc/12mth" />
      <ErrorPage path="/maintenance" />
      <DeviceDetailsPage path="/device-info" />
      <ContactInformationPage path="/policy-holder" />
      <TosPage path="/tos" />
      <DisclaimerPage path="/disclaimer" />
      <PageNoFound path="**" />
    </Router>
  )
}

export default App

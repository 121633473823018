import React from 'react';
import Layout from '../../components/shared/layout';
import TnC12MonthsComponent from '../../components/terms-and-conditions/tnc-12months.component';

const TnC12Months = () => {
  return (
    <div className="tnc-page">
      <Layout header={true} isBackBtn={true}>
        <TnC12MonthsComponent></TnC12MonthsComponent>
      </Layout>
    </div>
  )
}

export default TnC12Months;
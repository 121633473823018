import React from 'react';

// const xml = "<ssoResponse>" +
//     "<subscriberdata>" +
//     "<customer>" +
//     "<msisdn>436608450796</msisdn>" +
//     "<email>testmail@test.com</email>" +
//     "<salutation>HERR</salutation>" +
//     "<firstName>Anthony</firstName>" +
//     "<lastName>Stark</lastName>" +
//     "<companyName/>" +
//     "<address>" +
//     "<street1>Brünner Straße</street1>" +
//     "<street2>52</street2>" +
//     "<city>Wien</city>" +
//     "<zipCode>1210</zipCode>" +
//     "</address>" +
//     "</customer>" +
//     "<item>" +
//     "<description>iPhone 7 128GB Gold</description>" +
//     "<imei>333666333666333</imei>" +
//     "<manufacturerPartNumber>MN942ZD/A</manufacturerPartNumber>" +
//     "<grossPrice>400.0</grossPrice>" +
//     "<purchaseDate>2020-11-16</purchaseDate>" +
//     "<storeId>33606</storeId>" +
//     "<salesRepId/>" +
//     "<dreiOrderId>COM000099604524</dreiOrderId>" +
//     "<stockCode>KBX8422</stockCode>" +
//     "</item>" +
//     "</subscriberdata>" +
//     "<status>" +
//     "<statusCode>0</statusCode>" +
//     "<statusDescription>token valid</statusDescription>" +
//     "</status>" +
//     "</ssoResponse>"

// const xmlParser = new DOMParser();
// const parsedXML = xmlParser.parseFromString(xml, 'text/xml');
// console.log("xml", parsedXML);

export const getParsedXMLObj = (xml) => {
    const xmlParser = new DOMParser();
    const parsedXML = xmlParser.parseFromString(xml, 'text/xml');
    console.log("status", typeof (parsedXML.getElementsByTagName('statusCode')[0].innerHTML));
    if (parsedXML.getElementsByTagName('statusCode')[0] && parsedXML.getElementsByTagName('statusCode')[0].innerHTML === '0') {
        const parseXMLObj = {
            isValidXML: true, // used for our own validation purpose
            deviceImei: parsedXML.getElementsByTagName('imei')[0] && parsedXML.getElementsByTagName('imei')[0].innerHTML,
            customerPhoneNo: parsedXML.getElementsByTagName('msisdn')[0] && parsedXML.getElementsByTagName('msisdn')[0].innerHTML,
            phoneDescription: parsedXML.getElementsByTagName('description')[0] && parsedXML.getElementsByTagName('description')[0].innerHTML,
            kbxCode: parsedXML.getElementsByTagName('stockCode')[0] && parsedXML.getElementsByTagName('stockCode')[0].innerHTML,
            deviceActivationDate: parsedXML.getElementsByTagName('purchaseDate')[0] && parsedXML.getElementsByTagName('purchaseDate')[0].innerHTML,
            deviceRrp: parsedXML.getElementsByTagName('priceGross')[0] && parsedXML.getElementsByTagName('priceGross')[0].innerHTML,
            customerFirstName: parsedXML.getElementsByTagName('firstName')[0] && parsedXML.getElementsByTagName('firstName')[0].innerHTML,
            customerLastName: parsedXML.getElementsByTagName('lastName')[0] && parsedXML.getElementsByTagName('lastName')[0].innerHTML,
            customerSecondaryPhoneNo: parsedXML.getElementsByTagName('secondaryPhoneNo')[0] && parsedXML.getElementsByTagName('secondaryPhoneNo')[0].innerHTML,
            customerEmail: parsedXML.getElementsByTagName('email')[0] && parsedXML.getElementsByTagName('email')[0].innerHTML,
            companyName: parsedXML.getElementsByTagName('companyName')[0] && parsedXML.getElementsByTagName('companyName')[0].innerHTML,
            street1: parsedXML.getElementsByTagName('street1')[0] && parsedXML.getElementsByTagName('street1')[0].innerHTML,
            street2: parsedXML.getElementsByTagName('street2')[0] && parsedXML.getElementsByTagName('street2')[0].innerHTML,
            city: parsedXML.getElementsByTagName('city')[0] && parsedXML.getElementsByTagName('city')[0].innerHTML,
            zipCode: parsedXML.getElementsByTagName('zipCode')[0] && parsedXML.getElementsByTagName('zipCode')[0].innerHTML,
            deviceMake: parsedXML.getElementsByTagName('make')[0] && parsedXML.getElementsByTagName('make')[0].innerHTML,
            deviceModel: parsedXML.getElementsByTagName('model')[0] && parsedXML.getElementsByTagName('model')[0].innerHTML
        }
        return parseXMLObj;
    }
    else {
        console.log("token not valid");
        return {
            isValidXML: false
        }
    }
}

export const checkMandatoryFields = (xmlObj) => {
    //need to match keys with xml keys for confirmation
    const mandatoryFields = ['customerPhoneNo', 'deviceImei', 'deviceRrp', 'deviceActivationDate', 'kbxCode'];
    let flag = true;
    mandatoryFields.forEach(key => {
        if (!xmlObj[key]) {
            console.log("mandatory key not found in xml", key);

            flag = false;
        }
    })
    return flag;
}
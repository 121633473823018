import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import * as constants from '../app.constant';
import deviceStore from '../store/deviceStore';
import loaderStore from '../store/loaderStore';
import planStore from '../store/planStore';
import customerStore from '../store/customerStore';
import LandingComponent from '../components/landing/landing.component';
import Layout from '../components/shared/layout';
import { navigate } from "@reach/router";
import * as ProdConfig from '../service/prod-config'
import * as xmlParser from '../service/xml-parser';

const IndexPage = () => {

  const [isCustomerCanProceed, setMandatoryFlag] = useState(false);

  useEffect(() => {
    if (!JSON.parse(localStorage.getItem('isFromProductInfoPage'))) {
      localStorage.clear();
      customerStore.clear();
      deviceStore.clear();
      planStore.clear();
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get('token');
      // getData();
      if (myParam) {
        getXMLFromToken(myParam);
      }
      else {   // if someone opened page without token
        getProductDetail(false)
        setMandatoryFlag(false);
      }
    }
    else setMandatoryFlag(true);   //as it is coming from next page so it is already a valid situation
  }, []);

  const productList = useStaticQuery(graphql
    `query MyQuery {
      allProductList(filter: {clientProductName: {ne: null}}) {
        nodes {
          name: clientProductName
          coverageDesc: clientProductDescription
          pricingType
          productId: productCode
          productName
          tenure
          tenureType
          plans {
            currency
            devicePriceEndingRange
            devicePriceStartingRange
            retailPriceValueWithTax: totalRRPValueWithTax
            bandNo
            repairServiceFee
            replacementServiceFee
          }
        }
      }
    }`
  )

  const getData = () => {
    const detailsObj = xmlParser.getParsedXMLObj();
    // const validData = xmlParser.checkMandatoryFields(detailsObj);
    if (detailsObj.isValidXML) {
      const validData = xmlParser.checkMandatoryFields(detailsObj);
      setMandatoryFlag(validData);
      getProductDetail(detailsObj.deviceRrp || false);  // added false if device rrp is not present so that we can show product and will hide price using flag
      saveCustomerDetail(detailsObj);

      if (validData) {
        detailsObj.deviceImei = getRandomImei()
        getDeviceDetail(detailsObj);
      } else {
        saveDescriptionAsBrandModel(detailsObj);
        saveDeviceToStore(detailsObj)
      }
    }
    else {
      getProductDetail(false);   //false as device rrp sent to prod config so to hide prices of product
      setMandatoryFlag(false);   //to disable learn more button
    }
  }

  const getRandomImei = () => {
    return Math.floor(Math.random() * 1000000000000000);
  }


  const getXMLFromToken = (token) => {
    const url = `${process.env.GET_XML_API_URL}?token=${token}`;
    const method_options = {
      method: 'GET',
      headers: {
        "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
        "bolt-tenant-id": constants.HEADER_REQUEST.boltTenantId,
        "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode
      }
    }
    loaderStore.showLoader(true);
    fetch(url, method_options)
      .then(res => res.json())
      .then(response => {
        loaderStore.showLoader(false);
        if (response._status.code === '80001') {
          const detailsObj = xmlParser.getParsedXMLObj((response.xmlResponse));
          if (detailsObj.isValidXML) {
            const validData = xmlParser.checkMandatoryFields(detailsObj);
            setMandatoryFlag(validData);
            getProductDetail(detailsObj.deviceRrp || false);  // added false if device rrp is not present so that we can show product and will hide price using flag

            if (validData) {
              saveCustomerDetail(detailsObj);
              getDeviceDetail(detailsObj);
            } else {
              saveDescriptionAsBrandModel(detailsObj);
              saveDeviceToStore(detailsObj)
            }
          }
          else {
            getProductDetail(false);   //false as device rrp sent to prod config so to hide prices of product
            setMandatoryFlag(false);   //to disable learn more button
          }
        }
        else {
          // console.log("came here");
          getProductDetail(false);   //no device rrp sent to hide price
          setMandatoryFlag(false);   //to disable learn more button
        }
      })
      .catch(err => {
        loaderStore.showLoader(false);
        console.log("Came in error in xml api", err);
        getProductDetail(false);   //no device rrp sent to hide price
        setMandatoryFlag(false);   //to disable learn more button
      })
  }

  const saveCustomerDetail = (detailsObj) => {
    customerStore.updateCustomerDetail(detailsObj);
  }

  const getProductDetail = (deviceRrp) => {
    const fetchedProduct = JSON.parse(JSON.stringify(productList.allProductList.nodes));
    let productArray = ["AT_DREI_SMART_COVER_PLUS_1M", "AT_DREI_SMART_COVER_1M", "AT_DREI_ADLD_EW_1M"];

    const sortedArray = fetchedProduct.sort((a, b) => productArray.indexOf(a.productId) - productArray.indexOf(b.productId)); //sort according product list array
    planStore.saveProductArrToStore(sortedArray);
    const bandList = deviceRrp && ProdConfig.getBandList([Number(deviceRrp)], sortedArray[0]);
    bandList && planStore.saveBandList(bandList);
    const selectedPlanId = bandList && bandList.length && bandList[0].planId;
    const productArr = ProdConfig.getFinalProducts(sortedArray, selectedPlanId, true);  //sending false in planId if no device rrp present
    productArr && planStore.saveProductDisplayArrToStore(productArr);
  }

  const saveDeviceToStore = (deviceDetail) => {
    deviceStore.updateDeviceDetail(deviceDetail);
  }

  const saveDescriptionAsBrandModel = (detailsObj) => {
    detailsObj['deviceMake'] = detailsObj.phoneDescription;
    detailsObj['deviceModel'] = detailsObj.phoneDescription;
    detailsObj['skuCode'] = detailsObj.phoneDescription;
    return detailsObj;
  }

  //calling kbx api
  const getDeviceDetail = (detailsObj) => {
    loaderStore.showLoader(true);
    const url = process.env.ARTICLE_MASTER_API_URL + '?item_code=' + detailsObj.kbxCode;
    const method_options = {
      method: 'GET',
      headers: {
        "bolt-country-code": constants.HEADER_REQUEST.boltCountryCode,
        "bolt-tenant-id": constants.HEADER_REQUEST.boltTenantId,
        "bolt-language-code": constants.HEADER_REQUEST.boltLanguageCode
      }
    }
    fetch(url, method_options)
      .then(res => res.json())
      .then(response => {
        loaderStore.showLoader(false);
        console.log("Response", response);
        if (response._status.code === '80001') {
          //save device make and model to store
          const { device_make, device_model, sku_code } = response;
          if (device_make && device_model && sku_code) {
            detailsObj['deviceMake'] = device_make;
            detailsObj['deviceModel'] = device_model;
            detailsObj['skuCode'] = sku_code;
          } else {
            detailsObj = saveDescriptionAsBrandModel(detailsObj);
          }

        } else {
          detailsObj = saveDescriptionAsBrandModel(detailsObj);
          console.log("error in device info api");
        }
        (detailsObj.deviceMake && detailsObj.deviceModel) ? saveDeviceToStore(detailsObj) : setMandatoryFlag(false);
      })
      .catch(err => {
        loaderStore.showLoader(false);
        detailsObj = saveDescriptionAsBrandModel(detailsObj);
        (detailsObj.deviceMake && detailsObj.deviceModel) ? saveDeviceToStore(detailsObj) : setMandatoryFlag(false);
        console.log("error in device info api", err);
      })
  }

  return (
    <div className="landing-page">
      <Layout header={true} footer={true}>
        <LandingComponent canCustomerProceed={isCustomerCanProceed}></LandingComponent>
      </Layout>
    </div>
  )
}

export default IndexPage;